const stripePlan = {
    free:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:false,
        poll:false,
        calculater :false,
        surveys:false
    },
    starter:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:false,
        calculater :false,
        surveys:false

    },
    premium:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :false,
        surveys:false

    },
    professional:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :true,
        surveys:false

    },
    advanced:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :true,
        surveys:true

    },
};

const appsumo = {
    business:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :true,
        surveys:true

    },
    starter:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :true,
    },
    premium:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :true,
    },
    professional:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :true,
    },
    advanced:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :true,
    },
};

const bundlePlan = {
    free:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:false,
        poll:false,
        calculater :false,
        surveys:false

    },
    starter:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:false,
        calculater :false,
        surveys:false

    },
    premium:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :false,
        surveys:false

    },
    professional:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :true,
        surveys:true

    },
    enterprise:{
        quizzes:true,
        spinWheel:true,
        games:true,
        interactive_content:true,
        ecommerce:true,
        poll:true,
        calculater :true,
        surveys:true

    },
};


export const PlanFeaturePermission = {
    stripe:stripePlan,
    appsumo:appsumo,
    bundle:bundlePlan,
    Bundle:bundlePlan,
}
